import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../components/affix/AutoAffix";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import FormContatti from "../../components/form-contatti";

import Square from "../../svg/square-det.svg";
import SquareImgDx from "../../svg/square-img-dx.svg";
import SquareImgSx from "../../svg/square-img-sx.svg";

const hero = [
  {
    title: "Cloud Applications",
    description:
      "Il nuovo approccio architetturale MACH: Microservices, API-first, Cloud-native, Headless.",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "Cloud Applications" },
];

const CloudApplications = ({ data, location }) => {
  const { intro, evolution, headlessGatsby, serverless, schema } = data;
  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={`Cloud Applications`}
        description={`Il nuovo approccio architetturale MACH: Microservices, API-first, Cloud-native, Headless.`}
      />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                Il Competence Center <strong>Cloud Applications</strong> si occupa di sviluppo di
                portali e applicazioni su tecnologie <strong>Cloud native</strong> ed in particolare
                su architetture di <strong>Frontend</strong> legate al paradigma{" "}
                <strong>JAMStack</strong> (client-side “JavaScript”, reusable ”APIs”, and prebuilt
                “Markup”) unitamente a <strong>Backend</strong> che espongono servizi in modalità
                Headless e che si integrano in architetture di tipo MACH (“Microservices”,
                “API-first”, “Cloud-native”, “Headless”).
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-4 col-xl-7 offset-xl-5 mb-0 mb-xl-5">
              <div className="section__img-intro">
                <SquareImgDx className="square-top" />
                <SquareImgSx className="square-bottom" />
                <GatsbyImage image={getImage(intro)} alt="Image intro" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "approccio-architetturale-mach",
                        "headless",
                        "cosa-sono-i-cms-headless",
                        "come-funzionano-i-cms-headless",
                        "la-nostra-soluzione-serverless-con-contentful-e-aws",
                        "headless-cms-quali-sono-i-vantaggi",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#approccio-architetturale-mach`}
                          to={`approccio-architetturale-mach`}
                          className="anchor-nav__link"
                          title="Approccio architetturale MACH"
                        >
                          Approccio architetturale MACH
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#headless`}
                          to={`headless`}
                          className="anchor-nav__link"
                          title="Headless"
                        >
                          Headless
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#cosa-sono-i-cms-headless`}
                          to={`cosa-sono-i-cms-headless`}
                          className="anchor-nav__link"
                          title="Cosa sono i CMS Headless"
                        >
                          Cosa sono i CMS Headless
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#come-funzionano-i-cms-headless`}
                          to={`come-funzionano-i-cms-headless`}
                          className="anchor-nav__link"
                          title="Come funzionano i CMS Headless"
                        >
                          Come funzionano i CMS Headless
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#la-nostra-soluzione-serverless-con-contentful-e-aws`}
                          to={`la-nostra-soluzione-serverless-con-contentful-e-aws`}
                          className="anchor-nav__link"
                          title="La nostra soluzione serverless con Contentful e AWS"
                        >
                          La nostra soluzione serverless con Contentful e AWS
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#headless-cms-quali-sono-i-vantaggi`}
                          to={`headless-cms-quali-sono-i-vantaggi`}
                          className="anchor-nav__link"
                          title="Headless CMS quali sono i vantaggi?"
                        >
                          Headless CMS quali sono i vantaggi?
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="approccio-architetturale-mach" className="anchor">
                  <h2 className="anchor-text__title">Approccio architetturale MACH</h2>
                  <p>
                    Questo competence center, in rapida crescita, si occupa di progetti “
                    <strong>mission critical</strong>” per grandi organizzazioni che vogliono fare
                    di <strong>usabilità</strong>, <strong>scalabilità</strong>
                    <strong>sicurezza</strong> e <strong>performance</strong> le caratteristiche
                    principali dei propri sistemi digitali.Grazie a queste tecnologie, è possibile
                    supportare strategie aziendali secondo il modello della “
                    <strong>composable enterprise</strong>" in cui ogni componente è "pluggable",
                    scalabile, sostituibile, nell'ottica di miglioramento continuo in cui una
                    metodologia di sviluppo agile incontra i rapidi cambiamenti di necessità del
                    business.
                  </p>
                </div>
                <div id="headless" className="anchor">
                  <h2 className="anchor-text__title">Headless</h2>
                  <p>
                    Con l’approccio Headless CMS, e il relativo disaccoppiamento completo tra
                    sviluppo front-end e servizi di back-end, è possibile utilizzare piattaforme di
                    CMS in modo molto flessibile, agile e scalabile.
                  </p>
                  <p>
                    In questo modo si ha il vantaggio di mantenere la parte redazionale e di
                    back-end invariata, consentendo ai redattori di mantenere il proprio metodo di
                    editing dei contenuti, ma allo stesso tempo permettendo di avere un front-end
                    multichannel senza legacy di prodotto o tecnologia - e con elevatissime
                    performance e sicurezza.
                  </p>
                </div>
                <div id="cosa-sono-i-cms-headless" className="anchor">
                  <h2 className="anchor-text__title">Cosa sono i CMS Headless</h2>
                  <p>
                    Per chi ha particolari esigenze di sicurezza, performance e scalabilità, Ariadne
                    propone un <strong>nuovo approccio architetturale</strong> (
                    <a href="https://jamstack.org/" target="_blank" rel="noopener noreferrer">
                      JAMStack
                    </a>
                    ) che comporta l’utilizzo del proprio CMS (Liferay, WordPress, Contentful, etc)
                    come un Headless CMS insieme a{" "}
                    <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer">
                      Gatsby
                    </a>
                    .{" "}
                  </p>
                  <p>
                    Dopo tanti anni, abbiamo abbandonato l'utilizzo di CMS tradizionali orientandoci
                    verso un’architettura{" "}
                    <a href="https://jamstack.org/" target="_blank" rel="noopener noreferrer">
                      JAMStack
                    </a>
                    . I motivi che ci hanno portato a proporre questa scelta sono molteplici, sia di
                    natura tecnologica, sia tenendo conto delle prospettive future.{" "}
                  </p>
                  <p>
                    Gatsby è un <strong>generatore di siti statici</strong> fortemente orientato
                    alle prestazioni tanto da seguire il pattern architetturale{" "}
                    <a
                      href="https://web.dev/apply-instant-loading-with-prpl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PRPL
                    </a>
                    , inoltre è basato su <strong>tecnologie</strong> considerate di{" "}
                    <strong>frontiera</strong> quali <strong>React</strong> e{" "}
                    <strong>GraphQL</strong>. In altre parole, iniziare oggi ad utilizzare Gatsby dà
                    la possibilità di confrontarsi con gli aspetti più moderni del web.{" "}
                  </p>
                  <p>
                    Inoltre, adottare questa soluzione è il primo passo verso un’
                    <strong>architettura serverless</strong>, nella quale il contenuto stesso può
                    essere visto come servizio.{" "}
                  </p>
                  <p>
                    <strong>
                      JAM è un acronimo per Javascript API Markup. Non si tratta di un framework,
                      bensì di un approccio architetturale i cui principali obiettivi sono:
                    </strong>{" "}
                  </p>
                  <ul>
                    <li>sicurezza, </li>
                    <li>performance, </li>
                    <li>scalabilità </li>
                    <li>completo disaccoppiamento tra sviluppo front-end e servizi di back-end.</li>
                  </ul>
                  <p>
                    Uno dei concetti chiave di un'architettura JAM è il ritorno al sito "statico",
                    ovvero l'intero sito viene rigenerato (fase di build) ogni volta che avviene un
                    cambiamento e pubblicato tramite CDN. Attenzione che statico non implica
                    limitazioni nell'interazione utente, ma solamente che i file HTML/JS/CSS sono
                    file statici. I file javascript una volta caricati sul client possono interagire
                    con altri servizi rispondendo a qualunque esigenza funzionale (form, ricerche,
                    login, e-commerce, ecc...).
                  </p>
                  <GatsbyImage
                    image={getImage(evolution)}
                    alt="Image evolution"
                    className="w-100"
                  />
                </div>
                <div id="come-funzionano-i-cms-headless" className="anchor">
                  <h2 className="anchor-text__title">Come funzionano i CMS Headless</h2>
                  <p>
                    <strong>Un sito semi-statico</strong>
                  </p>
                  <p>
                    La parte "dinamica" dell'architettura viene usata principalmente dai redattori
                    per inserire contenuti (per esempio con cadenza settimanale), per il resto del
                    tempo le pagine sono sempre le stesse. In uno scenario come questo è sufficiente
                    garantire:
                  </p>
                  <ul>
                    <li>un'interfaccia redazionale per la gestione dei contenuti</li>
                    <li>
                      un processo che, a fronte di una richiesta di pubblicazione, generi
                      automaticamente le pagine statiche da pubblicare sul CDN
                    </li>
                    <li>poter visionare una preview prima della pubblicazione</li>
                    <li>un form per la raccolta dei contatti/richieste</li>
                  </ul>
                  <p>
                    <strong>La gestione dei contenuti</strong>
                  </p>
                  <p>
                    Considerate dunque queste esigenze, la soluzione proposta è l'impiego di un{" "}
                    <a
                      href="https://kontent.ai/headless-cms-guide"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Headless CMS
                    </a>
                    .{" "}
                  </p>
                  <p>
                    Esistono diversi tipologie di Headless CMS. Gli stessi{" "}
                    <a href="https://wordpress.com/it/" title="Wordpress" rel="noopener noreferrer">
                      Wordpress
                    </a>{" "}
                    e{" "}
                    <a href="https://www.drupal.org/" title="Drupal" rel="noopener noreferrer">
                      Drupal
                    </a>{" "}
                    (
                    <a
                      href="https://www.contentacms.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contenta
                    </a>
                    ) possono essere usati in questo modo.
                  </p>
                  <p>
                    In Ariadne, per gli ultimi progetti realizzati, abbiamo scelto{" "}
                    <a href="https://www.contentful.com/" target="_blank" rel="noopener noreferrer">
                      Contentful
                    </a>
                    , vista l’esigenza di avere un'architettura serverless. Provare Contentful è
                    stata anche l’occasione per sperimentare quale impatto avrebbe avuto sui
                    redattori un diverso approccio alla redazione dei contenuti: nello specifico,
                    davanti a Contenful, il redattore deve concentrarsi solo sul contenuto, senza
                    pensare al contesto in cui questo verrà presentato. Oltre all’ottima usabilità
                    dell’interfaccia, tra le funzionalità di Contentful che abbiamo apprezzato
                    maggiormente ci sono: le Media Asset API per la gestione lato front-end delle
                    immagini responsive, il multi-environments (sandbox) e la gestione del
                    multilinguismo.
                  </p>
                  <p>
                    <strong>Static Site Generator</strong>
                  </p>
                  <p>
                    È ormai dal 2015 che in Ariadne utilizziamo gli{" "}
                    <a
                      href="https://jamstack.org/generators/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SSG
                    </a>{" "}
                    (
                    <a href="https://assemble.io/" target="_blank" rel="noopener noreferrer">
                      Assemble
                    </a>
                    ) pper la realizzazione di prototipi da condividere e testare con i clienti.
                    Ancora oggi siamo convinti che qualunque SSG sia un valido strumento per
                    attività di rapid prototyping ma, per un sito il cui ciclo di vita non termini
                    con la prototipazione, abbiamo individuato in Gatsby la nostra soluzione ideale.
                  </p>
                  <p>
                    <strong>Gatsby</strong>
                  </p>
                  <GatsbyImage
                    image={getImage(headlessGatsby)}
                    alt="Image headless gatsby"
                    className="w-100"
                  />
                  <p>
                    <a href="https://www.gatsbyjs.com/" title="Gatsby JS" rel="noopener noreferrer">
                      Gatsby
                    </a>{" "}
                    è fortemente orientato alle prestazioni tanto da seguire il pattern
                    architetturale{" "}
                    <a
                      href="https://web.dev/apply-instant-loading-with-prpl/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PRPL
                    </a>
                    , inoltre è basato su tecnologie considerate di frontiera quali React e GraphQL.
                    In altre parole acquisire conoscenza in Gatsby vuol dire confrontarsi con gli
                    aspetti più moderni del web. Gatsby negli ultimi anni ha raccolto molti
                    finanziamenti e presenta una community in continua crescita, sicuramente non è
                    un progetto destinato a scomparire, anzi siamo convinti che con il crescere
                    delle metodologie JAMstack il suo ruolo sarà sempre più centrale.
                  </p>
                </div>
                <div id="la-nostra-soluzione-serverless-con-contentful-e-aws" className="anchor">
                  <h2 className="anchor-text__title">
                    La nostra soluzione serverless con Contentful e AWS
                  </h2>
                  <p>
                    Entriamo più nello specifico e analizziamo come queste componenti interagiscono
                    tra loro. In altre parole: come possiamo pilotare l’evento di generazione del
                    sito (build)? Dove viene eseguito il build? Come pubblicare il codice generato?
                  </p>
                  <p>Per rispondere a queste domante abbiamo valutato e testato due alternative:</p>
                  <ol>
                    <li>
                      Utilizzare{" "}
                      <a
                        href="https://aws.amazon.com/it/codepipeline/"
                        title="AWS CodePipeline"
                        rel="noopener noreferrer"
                      >
                        AWS CodePipeline
                      </a>{" "}
                      e{" "}
                      <a
                        href="https://aws.amazon.com/it/codebuild/"
                        title="AWS CodeBuild"
                        rel="noopener noreferrer"
                      >
                        AWS CodeBuild
                      </a>
                      .
                    </li>
                    <li>
                      <p>Dotarsi di un server ad uso interno in grado di: </p>
                      <p>
                        a. Esporre l’endpoint (tramite un pagina di amministrazione)
                        <br />
                        b. Eseguire il build
                        <br />
                        c. Esporre internamente la versione generata (sito di stage)
                        <br />
                        d. Sincronizzare il CDN (Bucket S3 di AWS){" "}
                      </p>
                    </li>
                  </ol>
                  <GatsbyImage image={getImage(schema)} alt="Image schema" className="w-100" />
                  <p>
                    <strong>Integrazione di servizi - il form contatti</strong>
                  </p>
                  <p>
                    Il form di contatti è gestito tramite AWS e più precisamente coinvolge le
                    seguente componenti:
                  </p>
                  <ol>
                    <li>
                      <a
                        href="https://aws.amazon.com/it/api-gateway/"
                        title="API Gateway"
                        rel="noopener noreferrer"
                      >
                        API Gateway
                      </a>
                      : endpoint per l’invio del form
                    </li>
                    <li>
                      <a
                        href="https://aws.amazon.com/it/lambda/"
                        title="AWS Lambda"
                        rel="noopener noreferrer"
                      >
                        Lambda Functions
                      </a>
                      : racchiude il codice che processa la richiesta
                    </li>
                    <li>
                      <a
                        href="https://aws.amazon.com/it/ses/"
                        title="SES AWS"
                        rel="noopener noreferrer"
                      >
                        SES (Simple Email Service)
                      </a>{" "}
                      è il servizio di Amazon usato per inviare l’email di notifica{" "}
                    </li>
                    <li>
                      <a
                        href="https://aws.amazon.com/it/dynamodb/"
                        title="DynamoDB"
                        rel="noopener noreferrer"
                      >
                        DynamoDB
                      </a>{" "}
                      è il database non relazionale in cui vengono salvate le risposte del Form AWS
                      serverless.
                    </li>
                  </ol>
                  <GatsbyImage
                    image={getImage(serverless)}
                    alt="Image serveless"
                    className="w-100"
                  />
                </div>
                <div id="headless-cms-quali-sono-i-vantaggi" className="anchor">
                  <h2 className="anchor-text__title">Headless CMS quali sono i vantaggi?</h2>
                  <p>
                    Entriamo più nello specifico e analizziamo come queste componenti interagiscono
                    tra loro. In altre parole: come possiamo pilotare l’evento di generazione del
                    sito (build)? Dove viene eseguito il build? Come pubblicare il codice generato?
                  </p>
                  <p>Per rispondere a queste domante abbiamo valutato e testato due alternative:</p>
                  <ol>
                    <li>
                      Utilizzare{" "}
                      <a
                        href="https://aws.amazon.com/it/codepipeline/"
                        title="AWS CodePipeline"
                        rel="noopener noreferrer"
                      >
                        AWS CodePipeline
                      </a>{" "}
                      e{" "}
                      <a
                        href="https://aws.amazon.com/it/codebuild/"
                        title="AWS CodeBuild"
                        rel="noopener noreferrer"
                      >
                        AWS CodeBuild
                      </a>
                      .
                    </li>
                    <li>
                      <p>Dotarsi di un server ad uso interno in grado di: </p>
                      <p>
                        a. Esporre l’endpoint (tramite un pagina di amministrazione)
                        <br />
                        b. Eseguire il build
                        <br />
                        c. Esporre internamente la versione generata (sito di stage)
                        <br />
                        d. Sincronizzare il CDN (Bucket S3 di AWS){" "}
                      </p>
                    </li>
                  </ol>
                  <p>
                    <strong>Integrazione di servizi - il form contatti</strong>
                  </p>
                  <p>
                    Il form di contatti è gestito tramite AWS e più precisamente coinvolge le
                    seguente componenti:
                  </p>
                  <ol>
                    <li>
                      <a
                        href="https://aws.amazon.com/it/api-gateway/"
                        title="API Gateway"
                        rel="noopener noreferrer"
                      >
                        API Gateway
                      </a>
                      : endpoint per l’invio del form
                    </li>
                    <li>
                      <a
                        href="https://aws.amazon.com/it/lambda/"
                        title="AWS Lambda"
                        rel="noopener noreferrer"
                      >
                        Lambda Functions
                      </a>
                      : racchiude il codice che processa la richiesta
                    </li>
                    <li>
                      <a
                        href="https://aws.amazon.com/it/ses/"
                        title="SES AWS"
                        rel="noopener noreferrer"
                      >
                        SES (Simple Email Service)
                      </a>{" "}
                      è il servizio di Amazon usato per inviare l’email di notifica{" "}
                    </li>
                    <li>
                      <a
                        href="https://aws.amazon.com/it/dynamodb/"
                        title="DynamoDB"
                        rel="noopener noreferrer"
                      >
                        DynamoDB
                      </a>{" "}
                      è il database non relazionale in cui vengono salvate le risposte del Form AWS
                      serverless.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default CloudApplications;

export const query = graphql`
  query {
    intro: file(relativePath: { eq: "pages/hedless.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    evolution: file(relativePath: { eq: "pages/headless-evolution.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    headlessGatsby: file(relativePath: { eq: "pages/headless-gatsby.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    serverless: file(relativePath: { eq: "pages/headless-serverless.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    schema: file(relativePath: { eq: "pages/headless-schema.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
  }
`;
